export const SubscriptionConfig = {
  api: {
    // get
    index: '/dashboard/subscriptions/',
    // post
    create: '/dashboard/subscriptions/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/subscriptions/${id}/`
    },
  },
  events: {
    name: 'subscription-plan',
    // refresh-index-data
    refresh: `rid-subscription-plan`,
    // slideover-right
    sorId: 'subscription-plan',
    sorOpen: 'sor-open-subscription-plan',
    sorClose: 'sor-close-subscription-plan',
    sorToggle: 'sor-toggle-subscription-plan',
    // editing-data
    editingData: 'edit-subscription-plan-data',
    // viewing-data
    viewingData: 'view-subscription-plan-data',
  },
}
